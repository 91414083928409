// icons
import LoadingIcon from "../images/icon/loadingIcon.svg";

export const pageTexts = (t) => {
  return {
    success:{
      title: {
        text: t("payment-feedback.successTitle"),
        colorClass: "colorDark",
        extraClass: "font40 m-0 lineHeight1",
      },
      subtitle: {
        text: t("payment-feedback.successSubtitle"),
        colorClass: "colorDarkLight",
        extraClass: "font35 lineHeight2 fontWeight600",
      },
      texts: [
        {
          text: t("payment-feedback.successFeedback"),
          colorClass: "colorDarkLight",
          extraClass: "font20 lineHeight1_3 pt-15",
          textType: "paragraph",
        },
        {
          text: t("payment-feedback.contactInfo"),
          colorClass: "colorDarkLight",
          extraClass: "font20 lineHeight1_7 termsStyle",
          textType: "paragraph",
        },
      ],
    },
    backHome: {
      text: t("payment-feedback.backHome"),
      url: "/",
      type: "whiteColor blackBorder",
      extraClass: "bigWidth font14",
    },
    failure: {
      title: {
        text: t("payment-feedback.failureTitle"),
        colorClass: "colorDark",
        extraClass: "font30 m-0 lineHeight1",
      },
      subtitle: {
        text: t("payment-feedback.failureSubtitle"),
        colorClass: "colorDarkLight",
        extraClass: "font30 pt-20 lineHeight1 fontWeight400",
      },
      texts: [
        {
          text: t("payment-feedback.contactInfo"),
          colorClass: "colorDarkLight",
          extraClass: "font20 lineHeight1_3 pt-15 termsStyle text-center",
          textType: "paragraph",
        },
      ],
    },
    expired: {
      title: {
        text: t("payment-feedback.expiredTitle"),
        colorClass: "colorDark",
        extraClass: "font30 m-0 lineHeight1",
      },
      texts: [
        {
          text: t("payment-feedback.contactInfo"),
          colorClass: "colorDarkLight",
          extraClass: "font20 lineHeight1_3 pt-15 termsStyle text-center",
          textType: "paragraph",
        },
      ],
    },
    redirect: {
      title: {
        text: t("redirect.redirecting"),
        colorClass: "colorDark",
        extraClass: "font34 m-0",
        heading: "h3",
      },
      text: {
        text: t("redirect.wait"),
        colorClass: "colorDark",
        extraClass: "font26",
        heading: "h4",
      },
      separator: true,
    },
    loading: {
        src: LoadingIcon,
        alt: t("redirect.loadingAlt")
    }
  };
};
