/**
 * Foursource page
 * https://foursource.com/payment-feedback/
 */
import { pageTexts } from "../content/payment-feedback";
import * as React from "react";
import { withTrans } from "../i18n/withTrans";

// components
import Layout from "../components/layout";
import TitleSeparator from "../components/titleSeparator";
import ButtonLink from "../components/_partials/_buttonLink";
import Text from "../components/text";
//svg
import failure from "../images/alert.svg";
import success from "../images/success.svg";

class PaymentFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.state = {
      status: new URLSearchParams(props.location.search).get("redirect_status")
    }

  }
  render() {
    let message;
    let status = this.state.status;
    switch(status) {
      case "succeeded":
        message = (
          <div className="pt-menu">
            <div className="d-flex justify-content-center text-center">
              <div className="pt-100 pb-100">
                <img src={success} alt="Success" className="height-80px"></img>
                <div className="pt-30">
                  <TitleSeparator info={this.text.success}></TitleSeparator>
                  <Text text={this.text.success.texts}></Text>
                  <div className="row justify-content-center pt-30 pb-30">
                    <ButtonLink
                      text={this.text.backHome.text}
                      url={this.text.backHome.url}
                      type={this.text.backHome.type}
                      extraClass={this.text.backHome.extraClass}
                    ></ButtonLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "failed":
        message = (
          <div className="pt-menu">
            <div className="d-flex justify-content-center text-center">
              <div className="pt-100 pb-100">
                <img src={failure} alt="Failure"></img>
                <div className="pt-30">
                  <TitleSeparator info={this.text.failure}></TitleSeparator>
                  <Text text={this.text.failure.texts}></Text>
                  <div className="row justify-content-center pt-30 pb-30">
                    <ButtonLink
                      text={this.text.backHome.text}
                      url={this.text.backHome.url}
                      type={this.text.backHome.type}
                      extraClass={this.text.backHome.extraClass}
                    ></ButtonLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      default:
        message = (
          <div className="pt-menu">
            <div className="d-flex justify-content-center text-center">
              <div className="pt-100 pb-100">
                <img src={failure} alt="Failure"></img>
                <div className="pt-30">
                  <TitleSeparator info={this.text.expired}></TitleSeparator>
                  <Text text={this.text.expired.texts}></Text>
                  <div className="row justify-content-center pt-30 pb-30">
                    <ButtonLink
                      text={this.text.backHome.text}
                      url={this.text.backHome.url}
                      type={this.text.backHome.type}
                      extraClass={this.text.backHome.extraClass}
                    ></ButtonLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    } 
    return (
      <Layout pageName="payment-feedback" whiteMenu={true}>
        {message}
      </Layout>
    );
  }
}

export default withTrans(PaymentFeedback);
